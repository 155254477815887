import "react-tabs/style/react-tabs.css";

import ContentWrapper from "components/ContentWrapper";
import Grid from "components/Grid";
import Header from "components/Header";
import About from "components/Icons/About";
import Loader from "components/Loader";
import ModalNPVRRecord from "components/ModalNPVRRecord";
import ThumbnailLibrary from "components/ThumbnailLibrary";
import ThumbnailRecord from "components/ThumbnailRecord";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal } from "helpers/localStorage";
import { transactionalOffersStatus } from "helpers/utility";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { compose } from "redux";
import { TucanoActions, TucanoModels, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageMyLibrary extends PageAbstract {
  state = {
    selectedTab: 0,
    validAssets: null,
    historyAssets: null,
  };

  static async fetch(dispatch, isConnected, index) {
    if (isConnected) {
      await dispatch(
        TucanoActions.getActiveAssets({
          languageId: getFromLocal("languageId"),
          allAssets: index,
          commercialModel: "TVOD",
          contentTypeFilter: "movie+episode",
        })
      );
    }
  }
  async componentDidMount() {
    const { t, dispatch } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (this.props.isConnected) {
      if (consts?.["features-availability"]?.["is-npvr-enabled"]) {
        await dispatch(TucanoActions.getNPVRRecords());
        await dispatch(TucanoActions.getNpvrQuota());
      }
      if (
        consts?.["features-availability"]?.["offers-transactional"] ===
        transactionalOffersStatus.Enabled
      ) {
        await PageMyLibrary.fetch(
          this.props.dispatch,
          this.props.isConnected,
          1
        ).then(async () => {
          await this.setState({ historyAssets: this.props.activeAssetsTVOD });
        });
        await PageMyLibrary.fetch(
          this.props.dispatch,
          this.props.isConnected,
          0
        ).then(async () => {
          const validAssets = this.props.activeAssetsTVOD;
          const allAssets = this.state.historyAssets;
          if (allAssets && validAssets) {
            const history = allAssets.reduce((initArray, item) => {
              let found = false;
              validAssets.forEach((el) => {
                if (el.id === item.id) found = true;
              });
              if (found === false) {
                initArray.push(item);
              }
              return initArray;
            }, []);
            await this.setState({
              validAssets: validAssets,
              historyAssets: history,
            });
          }
        });
      }
    } else {
      this.props.history.push("?login=1");
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const {
      activeAssetsLoading,
      viewport,
      isConnected,
      records,
      quota,
      modalNPVRInfo,
      activeProfile,
    } = this.props;
    const { validAssets, historyAssets } = this.state;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    const consumed =
      quota instanceof TucanoModels.Quota &&
      quota.getConsumedInHoursAndMinutes();
    const available =
      quota instanceof TucanoModels.Quota &&
      quota.getAvailableInHoursAndMinutes();
    const recordQuota =
      quota instanceof TucanoModels.Quota &&
      quota.getRecordQuotaInhoursAndMinutes();
    const isTVODEnabled =
      consts?.["features-availability"]?.["offers-transactional"] ===
      transactionalOffersStatus.Enabled;
    const isNPVREnabled =
      consts?.["features-availability"]?.["is-npvr-enabled"];
    return (
      <Fragment>
        <ContentWrapper>
          <div className={style.root}>
            <Header>
              <Trans>{this.props.route.title}</Trans>
            </Header>
            <Tabs
              className={style.tabsContainer}
              selectedIndex={this.state.selectedTab}
              onSelect={(tabIndex) => this.setState({ selectedTab: tabIndex })}
            >
              <TabList>
                {isTVODEnabled && (
                  <>
                    <Tab>
                      <Trans>Rentals</Trans>
                    </Tab>
                    <Tab>
                      <Trans>Past rentals</Trans>
                    </Tab>
                  </>
                )}
                {isNPVREnabled && (
                  <Tab>
                    <Trans>Records</Trans>
                  </Tab>
                )}
              </TabList>

              {isTVODEnabled && (
                <>
                  <TabPanel>
                    {activeAssetsLoading && <Loader centered={true} />}
                    {!activeAssetsLoading && (
                      <div className={style.activeAssetsContainer}>
                        {(!validAssets || validAssets.length === 0) && (
                          <p className={style.title}>
                            <Trans>There is no rental</Trans>
                          </p>
                        )}
                        {validAssets && validAssets.length !== 0 && (
                          <>
                            <Grid
                              rootClassName={
                                consts.designMode === 169
                                  ? style.gridCover
                                  : style.grid
                              }
                            >
                              {validAssets.map((asset, index) => {
                                return (
                                  <ThumbnailLibrary
                                    key={index}
                                    asset={asset}
                                    thumbnailSize={thumbnailSize}
                                    viewport={viewport}
                                    subscriptionModal={this.subscriptionModal.bind(
                                      this
                                    )}
                                    profile={activeProfile}
                                  />
                                );
                              })}
                            </Grid>
                          </>
                        )}
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {activeAssetsLoading && <Loader centered={true} />}
                    {!activeAssetsLoading && (
                      <>
                        {(!historyAssets || historyAssets.length === 0) && (
                          <p className={style.title}>
                            <Trans>There is no rental</Trans>
                          </p>
                        )}
                        {historyAssets && historyAssets.length !== 0 && (
                          <>
                            <Grid
                              rootClassName={
                                consts.designMode === 169
                                  ? style.gridCover
                                  : style.grid
                              }
                            >
                              {historyAssets.map((asset, index) => {
                                return (
                                  <ThumbnailLibrary
                                    key={index}
                                    asset={asset}
                                    thumbnailSize={thumbnailSize}
                                    viewport={viewport}
                                    subscriptionModal={this.subscriptionModal.bind(
                                      this
                                    )}
                                    isLocked={true}
                                    profile={activeProfile}
                                  />
                                );
                              })}
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </TabPanel>
                </>
              )}
              {isNPVREnabled && (
                <TabPanel>
                  {typeof quota === "object" && quota !== null && (
                    <div className={style.breadcrumb}>
                      <div className={style.column}>
                        <About className={style.aboutIcon} />
                        <Trans>Your recording quota status</Trans>
                      </div>
                      <div className={style.column}>
                        <div className={style.title}>
                          <Trans>Recorded</Trans>:
                        </div>
                        <small className={style.hours}>
                          {consumed.hours}
                          <Trans>h</Trans> {consumed.minutes}
                          <Trans>m</Trans>
                        </small>
                      </div>
                      <div className={style.column}>
                        <div className={style.title}>
                          <Trans>Available</Trans>:
                        </div>
                        <small className={style.hours}>
                          {available.hours}
                          <Trans>h</Trans> {available.minutes}
                          <Trans>m</Trans>
                        </small>
                      </div>
                      <div className={style.column}>
                        <div className={style.title}>
                          <Trans>Records quota</Trans>:
                        </div>
                        <small className={style.hours}>
                          {recordQuota.hours}
                          <Trans>h</Trans> {recordQuota.minutes}
                          <Trans>m</Trans>
                        </small>
                      </div>
                    </div>
                  )}
                  {records && records.length !== 0 && (
                    <>
                      <Grid
                        rootClassName={
                          consts.designMode === 169
                            ? style.gridCover
                            : style.grid
                        }
                      >
                        {records.map((record, _index) => {
                          return (
                            <ThumbnailRecord
                              key={record.getIdRecord()}
                              isConnected={isConnected}
                              thumbnailSize={thumbnailSize}
                              record={record}
                            />
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </TabPanel>
              )}
            </Tabs>
          </div>
        </ContentWrapper>
        {modalNPVRInfo?.showModalNPVR && <ModalNPVRRecord />}
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      activeAssetsLoading: state.content.activeAssetsTVOD.loading,
      activeAssetsTVOD: TucanoSelectors.getActiveAssetsTVOD(state),
      records: TucanoSelectors.getAllNPVRRecords(state),
      quota: TucanoSelectors.getQuotaNPVR(state),
      toasts: state.ui.toasts,
      viewport: state.ui.viewport,
      modalNPVRInfo: state.ui.modalNPVRInfo,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageMyLibrary);
