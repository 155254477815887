import { setLoginShow } from "actions/ui";
import { addToast } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Input, { InputType } from "components/Input";
import Logo from "components/LogoLogin";
import Modal from "components/Modal";
import consts from "consts/consts";
import FormAskResetPassword from "containers/FormAskResetPassword";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

class loginCredential extends Component {
  state = {
    username: "",
    password: "",
    show: false,
    error: false,
    showValidationModal: false,
    showModalForm: false,
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleKeyPressed(event) {
    if (event.key === "Enter" && !this.state.show) {
      this.handleLogin();
    }
  }
  showModal() {
    this.setState({ show: true });
  }

  hideModal() {
    this.setState({ show: false });
  }
  async handleLogin() {
    const { t, dispatch } = this.props;
    this.setState({ isLoginLoading: true });
    const isSafari = getFromLocal("isSafari");
    return this.props
      .dispatch(
        TucanoActions.login(
          this.state.username,
          this.state.password,
          isSafari ? consts.safariApiKey : consts.apiKey,
          consts.graphQLGrantType
        )
      )
      .then(() => {
        dispatch(setLoginShow(false));
        saveInLocal("authMode", "credential");
        dispatch(TucanoActions.getActiveProducts()).then(async (data) => {
          if (data && data.productIds && data.productIds.length === 0) {
            dispatch(TucanoActions.addProducts([consts.productId]));
          }
        });
        dispatch(TucanoActions.getAccountInfos()).then(async (data) => {
          if (data.status === "INACTIVE" || data.status === "SUSPENDED") {
            let found = this.props.toasts.find((element) =>
              element.text.includes(
                t(
                  "Your account has been suspended,Please contact our customer service"
                )
              )
            )
              ? true
              : false;
            if (!found) {
              await dispatch(
                addToast({
                  text: t(
                    "Your account has been suspended,Please contact our customer service"
                  ),
                  className: "error",
                  stayOpen: 1,
                })
              );
            }
          }
          const userInfo = data.additionalInformation;
          let isConnectedFromLocalISP = false;
          if (userInfo && userInfo.isp) {
            isConnectedFromLocalISP = true;
          }
          saveInLocal(consts.storageKeys.localISP, isConnectedFromLocalISP);
          await dispatch(
            TucanoActions.getListOptions(getFromLocal("languageId"))
          );
          await dispatch(
            TucanoActions.getListOptions(getFromLocal("languageId"), "ppv")
          );
          const productId = getFromLocal("productId") || consts.productId;
          await dispatch(TucanoActions.getOptionValidity(productId)).then(
            () => {
              this.setState({ isLoginLoading: false });
            }
          );
        });
      })
      .catch((_error) => {
        // Catch error
        this.setState({ error: true });
      });
  }

  showModalForm = (value) => {
    this.setState({ showModalForm: value });
  };

  renderForm = (asModal) => {
    const { loginError, isLoginLoading, t, Translate } = this.props;
    const { error } = this.state;
    const buttonType = isLoginLoading ? ButtonType.LOADING : ButtonType.LOGIN;
    return (
      <>
        {asModal && (
          <Logo
            className={
              consts.logoType === "RECTANGLE" ? style.logoRectangle : style.logo
            }
            type={consts.logoType || "SQUARE"}
          />
        )}
        <div className={style.inputs}>
          <Input
            rootClassName={style.input}
            type={InputType.TEXT}
            placeholder={t("Your email address")}
            onChange={(value) => this.setState({ username: value })}
            value={this.state.username}
          />
          <Input
            rootClassName={style.input}
            type={InputType.PASSWORD}
            placeholder={t("Your password")}
            onChange={(value) => this.setState({ password: value })}
            value={this.state.password}
          />
        </div>
        <div className={style.errorMessage}>
          {loginError && loginError.code === -429 && error && (
            <p>
              <Translate t={t}>
                You failed too many login attempts. Please try again later
              </Translate>
            </p>
          )}
          {loginError &&
            (loginError.code === -1 ||
              (loginError.code === 1 &&
                loginError.message === "Invalid auth")) &&
            error && (
              <p>
                <Translate t={t}>Invalid credentials</Translate>
              </p>
            )}
        </div>
        {consts.isResetPasswordEnabled && (
          <div className={style.actionButtonContainer}>
            <div className={style.forgottenPassword}>
              <span className={style.text}>
                <Translate t={t}>Forgotten password ?</Translate>
              </span>
              <Button
                rootClassName={style.forgottenButton}
                type={ButtonType.LOGINLINK}
                onClick={
                  !consts.externalResetPasswordLink && this.showModal.bind(this)
                }
                href={consts.externalResetPasswordLink || undefined}
                target={consts.externalResetPasswordLink ? "_blank" : undefined}
              >
                <Translate t={t}>Reset It</Translate>
              </Button>
            </div>
          </div>
        )}
        <div
          className={classnames(style.actionButtonContainer, style.loginButton)}
        >
          <Button
            rootClassName={style.actionButton}
            type={buttonType}
            onClick={this.handleLogin.bind(this)}
          >
            <Translate t={t}>Login</Translate>
          </Button>
        </div>
      </>
    );
  };

  render() {
    const { loginAsPopup, t, Translate } = this.props;
    const { show, showModalForm } = this.state;

    return (
      <>
        <div className={style.credentials}>
          <p>
            {t("To log in, encode your {{appName}} credentials", {
              appName: consts.name,
            })}
          </p>
        </div>
        {consts.loginWithCredentialsAsSingleButton && !loginAsPopup ? (
          <div className={style.openInModalContainer}>
            <Button
              rootClassName={style.openInModal}
              type={ButtonType.LOGINBORDERED}
              onClick={this.showModalForm.bind(this, true)}
            >
              <Translate t={t}>Login with Credential</Translate>
            </Button>
          </div>
        ) : (
          this.renderForm()
        )}
        <div className={style.actionButtonContainer}>
          {show && (
            <Modal
              size={"md"}
              rootClassName={style.modal}
              leftContent={{ title: t("Forgotten password") }}
              rightContent={{ canDismiss: this.hideModal.bind(this) }}
              overlay={true}
            >
              <FormAskResetPassword
                onCloseClick={this.hideModal.bind(this)}
                email={this.state.username}
              />
            </Modal>
          )}
          {showModalForm && (
            <Modal
              size={"md"}
              rootClassName={style.modal}
              leftContent={{ title: t("Log in to continue") }}
              rightContent={{
                canDismiss: this.showModalForm.bind(this, false),
              }}
              overlay={true}
            >
              {this.renderForm(true)}
            </Modal>
          )}
        </div>
      </>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoginLoading: state.account.login.loading,
      loginError: state.account.login.error,
    };
  }),
  withTranslation()
)(withRouter(loginCredential));
