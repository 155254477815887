import actionTypes from "../consts/actionTypes";

const initialState = {
  createAccount: {
    loading: false,
    data: undefined,
  },
  addProducts: {
    loading: false,
    data: undefined,
  },
  addOptions: {
    loading: false,
    data: undefined,
  },
  removeOptions: {
    loading: false,
    data: undefined,
  },
  checkOrderStatus: {
    loading: false,
    data: undefined,
  },
  askPayment: {
    loading: false,
    data: undefined,
  },
  executePayment: {
    loading: false,
    data: undefined,
  },
  availableOffers: {
    loading: false,
    data: undefined,
  },
  ppvOffers: {
    loading: false,
    data: [],
  },
  transactionalOffers: {
    loading: false,
    data: [],
  },
  listOptions: {
    loading: false,
    data: undefined,
  },
  ppvOptions: {
    loading: false,
    data: undefined,
  },
  activeProducts: {
    loading: false,
    data: undefined,
  },
  optionValidity: {
    loading: false,
    data: undefined,
  },
  listPaymentMethods: {
    loading: false,
    data: undefined,
  },
  validateVoucher: {
    loading: false,
    data: undefined,
  },
  promotions: {
    loading: false,
    data: undefined,
  },
  listCountries: {
    loading: false,
    data: undefined,
  },
  document: {
    loading: false,
    data: undefined,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ACCOUNT_PENDING: {
      return {
        ...state,
        createAccount: {
          loading: true,
        },
      };
    }
    case actionTypes.CREATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        createAccount: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    }
    case actionTypes.CHECK_ORDER_STATUS_PENDING: {
      return {
        ...state,
        checkOrderStatus: {
          loading: true,
          data: undefined,
        },
      };
    }
    case actionTypes.CHECK_ORDER_STATUS_ERROR:
    case actionTypes.CHECK_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        checkOrderStatus: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.ORDER_STATUS_RESET: {
      return {
        ...state,
        checkOrderStatus: initialState.checkOrderStatus,
      };
    }
    case actionTypes.CREATE_ACCOUNT_ERROR: {
      return {
        ...state,
        createAccount: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    }
    case actionTypes.ADD_PRODUCTS_PENDING: {
      return {
        ...state,
        addProducts: {
          loading: true,
        },
      };
    }
    case actionTypes.ADD_PRODUCTS_SUCCESS: {
      return {
        ...state,
        addProducts: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.ADD_PRODUCTS_ERROR: {
      return {
        ...state,
        addProducts: {
          error: action.payload,
        },
      };
    }
    case actionTypes.ADD_OPTIONS_PENDING: {
      return {
        ...state,
        addOptions: {
          loading: true,
        },
      };
    }
    case actionTypes.ADD_OPTIONS_SUCCESS: {
      return {
        ...state,
        addOptions: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.ADD_OPTIONS_ERROR: {
      return {
        ...state,
        addOptions: {
          data: action.payload,
        },
      };
    }
    case actionTypes.REMOVE_OPTIONS_PENDING: {
      return {
        ...state,
        removeOptions: {
          loading: true,
        },
      };
    }
    case actionTypes.REMOVE_OPTIONS_SUCCESS: {
      return {
        ...state,
        removeOptions: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.REMOVE_OPTIONS_ERROR: {
      return {
        ...state,
        removeOptions: {
          error: action.payload,
        },
      };
    }
    case actionTypes.ASK_PAYMENT_PENDING: {
      return {
        ...state,
        askPayment: {
          loading: true,
        },
      };
    }
    case actionTypes.ASK_PAYMENT_SUCCESS: {
      return {
        ...state,
        askPayment: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.ASK_PAYMENT_ERROR: {
      return {
        ...state,
        askPayment: {
          error: action.payload,
        },
      };
    }
    case actionTypes.EXECUTE_PAYMENT_PENDING: {
      return {
        ...state,
        executePayment: {
          loading: true,
        },
      };
    }
    case actionTypes.EXECUTE_PAYMENT_SUCCESS: {
      return {
        ...state,
        executePayment: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.EXECUTE_PAYMENT_ERROR: {
      return {
        ...state,
        executePayment: {
          loading: false,
          error: action.payload,
        },
      };
    }
    case actionTypes.VALIDATE_VOUCHER_PENDING: {
      return {
        ...state,
        validateVoucher: {
          loading: true,
        },
      };
    }
    case actionTypes.VALIDATE_VOUCHER_SUCCESS: {
      return {
        ...state,
        validateVoucher: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.VALIDATE_VOUCHER_ERROR: {
      return {
        ...state,
        validateVoucher: {
          error: action.payload,
        },
      };
    }
    case actionTypes.RESET_VOUCHER_DATA: {
      return {
        ...state,
        validateVoucher: initialState.validateVoucher,
      };
    }
    case actionTypes.ASK_CHANGE_BANK_INFORMATION_PENDING: {
      return {
        ...state,
        askChangeBankInformation: {
          loading: true,
        },
      };
    }
    case actionTypes.ASK_CHANGE_BANK_INFORMATION_SUCCESS: {
      return {
        ...state,
        askChangeBankInformation: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.ASK_CHANGE_BANK_INFORMATION_ERROR: {
      return {
        ...state,
        askChangeBankInformation: {
          error: action.payload,
        },
      };
    }
    case actionTypes.AVAILABLE_OFFERS_PENDING: {
      return {
        ...state,
        availableOffers: {
          loading: true,
        },
      };
    }
    case actionTypes.AVAILABLE_OFFERS_SUCCESS: {
      return {
        ...state,
        availableOffers: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.AVAILABLE_OFFERS_ERROR: {
      return {
        ...state,
        availableOffers: {
          error: action.payload,
        },
      };
    }
    case actionTypes.PPV_OFFERS_PENDING: {
      return {
        ...state,
        ppvOffers: {
          loading: true,
        },
      };
    }
    case actionTypes.PPV_OFFERS_SUCCESS: {
      return {
        ...state,
        ppvOffers: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.PPV_OFFERS_ERROR: {
      return {
        ...state,
        ppvOffers: {
          error: action.payload,
        },
      };
    }
    case actionTypes.TRANSACTIONAL_OFFERS_PENDING: {
      return {
        ...state,
        transactionalOffers: {
          loading: true,
        },
      };
    }
    case actionTypes.TRANSACTIONAL_OFFERS_SUCCESS: {
      return {
        ...state,
        transactionalOffers: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.TRANSACTIONAL_OFFERS_ERROR: {
      return {
        ...state,
        transactionalOffers: {
          error: action.payload,
        },
      };
    }
    case actionTypes.AVAILABLE_OFFERS_VOUCHER_PENDING: {
      return {
        ...state,
        promotions: {
          loading: true,
        },
      };
    }
    case actionTypes.AVAILABLE_OFFERS_VOUCHER_SUCCESS: {
      return {
        ...state,
        promotions: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.AVAILABLE_OFFERS_VOUCHER_ERROR: {
      return {
        ...state,
        promotions: {
          error: action.payload,
        },
      };
    }
    case actionTypes.LIST_OPTIONS_PENDING: {
      return {
        ...state,
        listOptions: {
          loading: true,
        },
      };
    }
    case actionTypes.LIST_OPTIONS_SUCCESS: {
      return {
        ...state,
        listOptions: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.LIST_OPTIONS_ERROR: {
      return {
        ...state,
        listOptions: {
          error: action.payload,
        },
      };
    }
    case actionTypes.LIST_PPV_OPTIONS_PENDING: {
      return {
        ...state,
        ppvOptions: {
          loading: true,
        },
      };
    }
    case actionTypes.LIST_PPV_OPTIONS_SUCCESS: {
      return {
        ...state,
        ppvOptions: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.LIST_PPV_OPTIONS_ERROR: {
      return {
        ...state,
        ppvOptions: {
          error: action.payload,
        },
      };
    }
    case actionTypes.ACTIVEPRODUCTS_PENDING: {
      return {
        ...state,
        activeProducts: {
          loading: true,
        },
      };
    }
    case actionTypes.ACTIVEPRODUCTS_SUCCESS: {
      return {
        ...state,
        activeProducts: {
          loading: false,
          data: action.payload.productIds,
        },
      };
    }
    case actionTypes.ACTIVEPRODUCTS_ERROR: {
      return {
        ...state,
        activeProducts: {
          error: action.payload,
        },
      };
    }
    case actionTypes.OPTION_VALIDITY_PENDING: {
      return {
        ...state,
        optionValidity: {
          loading: true,
        },
      };
    }
    case actionTypes.OPTION_VALIDITY_SUCCESS: {
      return {
        ...state,
        optionValidity: {
          loading: false,
          data: action.payload.options,
        },
      };
    }
    case actionTypes.OPTION_VALIDITY_ERROR: {
      return {
        ...state,
        optionValidity: {
          error: action.payload,
        },
      };
    }
    case actionTypes.LIST_PAYMENT_METHODS_PENDING: {
      return {
        ...state,
        listPaymentMethods: {
          loading: true,
        },
      };
    }
    case actionTypes.LIST_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        listPaymentMethods: {
          loading: false,
          data: action.payload.listPaymentMethods,
        },
      };
    }
    case actionTypes.LIST_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        listPaymentMethods: {
          error: action.payload,
        },
      };
    }
    case actionTypes.LIST_COUNTRY_PENDING: {
      return {
        ...state,
        data: {
          loading: true,
        },
      };
    }
    case actionTypes.LIST_COUNTRY_SUCCESS: {
      return {
        ...state,
        data: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.LIST_COUNTRY_ERROR: {
      return {
        ...state,
        data: {
          loading: false,
          data: action.payload,
        },
      };
    }
    case actionTypes.DOCUMENT_PENDING: {
      return {
        ...state,
        document: {
          loading: true,
        },
      };
    }
    case actionTypes.DOCUMENT_SUCCESS: {
      return {
        ...state,
        document: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    }
    case actionTypes.DOCUMENT_ERROR: {
      return {
        ...state,
        document: {
          loading: false,
          meta: action.meta,
          data: action.payload,
        },
      };
    }
    case actionTypes.RESET_GLOBAL_STATE:
      return initialState;
    default:
      return state;
  }
};
