import { createSelector } from "reselect";

import { isSameDay } from "../helpers/timeHelper.js";
import { slugify } from "../helpers/urlHelper.js";
import Channel from "../models/Channel";
import Faq from "../models/Faq";
import Strate from "../models/Strate";
import TVShow from "../models/TVShow";
import { selectListOptions, selectOptionValidity, selectPpvOptions } from "./subscription.js";

export const selectMenuOrder = (state) => {
  if (state?.reference?.menuorder === undefined) {
    return [];
  }

  return state.reference.menuorder.data;
};

const selectMenuItem = (state, parameters) => {
  const id = parseInt(parameters);
  if (!state.reference.menuorder.data) {
    return {};
  }
  const a = state.reference.menuorder.data.find((item) => {
    return item.idType === id;
  });
  return a;
};

const selectCatalog = (state) => {
  if (!state.reference.hierarchy.data) {
    return undefined;
  }
  return state.reference.hierarchy.data;
};

export const getCatalog = createSelector(selectCatalog, selectMenuItem, (catalogs, menuItem) => {
  if (!catalogs || !Array.isArray(catalogs)) {
    return undefined;
  }
  return catalogs.find((item) => {
    return item.idCatalog === menuItem?.id;
  });
});

const selectCatalogForId = (state, searchedCatalogId) => {
  const myCatalog = state.reference.hierarchy.data.find((item) => {
    return item.idCatalog === searchedCatalogId;
  });
  return myCatalog;
};

export const selectChannels = (state) => {
  if (!state.reference.channels.data) {
    return undefined;
  }
  return state.reference.channels.data;
};

export const getCatchupChannels = createSelector(
  selectCatalogForId,
  selectChannels,
  selectPpvOptions,
  selectListOptions,
  selectOptionValidity,
  (data, listChannels, ppvOptions, listOptions, optionsValidity) => {
    const channels = data.categories
      .map((item) => {
        const chan = listChannels && listChannels.find((value) => value.name === item.name);
        if (item.fictif) {
          return undefined;
        }
        let catchupChannel = new Channel(item, undefined, 540, listOptions, optionsValidity, ppvOptions);
        // Force isFta and isSubscribed because VOD category
        catchupChannel.setSubscribed(true);
        if (chan) {
          catchupChannel.setFta(chan.isFta);
        } else {
          catchupChannel.setFta(false);
        }

        return catchupChannel;
      })
      .filter(Boolean);
    return channels;
  }
);

export const getChannels = createSelector(
  selectChannels,
  selectPpvOptions,
  selectListOptions,
  selectOptionValidity,
  (channels, ppvOptions, listOptions, optionsValidity) => {
    if (!channels || !Array.isArray(channels)) {
      return undefined;
    }
    const data = channels
      .sort((a, b) => {
        return a.localizeNumber - b.localizeNumber;
      })
      .map((channel) => {
        return new Channel(channel, undefined, undefined, listOptions, optionsValidity, ppvOptions);
      });
    return data;
  }
);

export const getChannelById = (state, channelId) => {
  return createSelector(
    selectChannels,
    selectPpvOptions,
    selectListOptions,
    selectOptionValidity,
    (channels, ppvOptions, listOptions, optionsValidity) => {
      if (!channels || !Array.isArray(channels)) {
        return undefined;
      }
      const channel = channels.find((channel) => {
        return channel.idChannel === parseInt(channelId, 10);
      });
      if (channel) {
        return new Channel(channel, undefined, undefined, listOptions, optionsValidity, ppvOptions);
      }
    }
  )(state, channelId);
};

//FAQ
const selectFaqContent = (state) => {
  if (!state.reference.faq || !state.reference.faq.data) {
    return [];
  }
  return state.reference.faq.data;
};

export const getFaqContent = createSelector(selectFaqContent, (data) => {
  const faqContent = [];
  if (!data.faq) {
    return undefined;
  } else {
    data.faq.forEach((item) => {
      return item?.sections[0]?.items?.forEach((section) => {
        faqContent.push(new Faq(section));
        return section?.items?.map((faq) => {
          return faqContent.push(new Faq(faq));
        });
      });
    });
  }

  return faqContent;
});

//Privacy
const selectPrivacyContent = (state) => {
  if (!state.reference.faq.data || !state.reference.faq.data.faq) {
    return [];
  }
  return state.reference.faq.data.faq[0];
};

export const getPrivacyContent = createSelector(selectPrivacyContent, (data) => {
  if (data.sections) {
    const privacy = new Faq(data.sections[2]);
    return privacy;
  }
});

// This export is used in selector/content for asset breadcrumb
export const selectCategoryForId = (state, id) => {
  let category = undefined;
  if (state) {
    for (let i = 0; i < state?.length; i++) {
      const item = state[i];
      if (item.idCatalog === id) {
        category = item;
        break;
      } else {
        if (item.categories.length > 0) {
          const result = selectCategoryForId(item.categories, id);
          if (result) {
            category = result;
            break;
          }
        }
      }
    }
  }
  return category;
};

// This export is used in selector/content for tvshow breadcrumb
export const selectParentCategoryForId = (state, id) => {
  for (let i = 0; i < state?.length; i++) {
    const item = state[i];
    if (item.categories.length === 0) {
      return;
    }
    for (let j = 0; j < item.categories.length; j++) {
      const itemChild = item.categories[j];
      if (itemChild.idCatalog === id) {
        return item;
      }
    }
    for (let j = 0; j < item.categories.length; j++) {
      const result = selectParentCategoryForId(item.categories, id);
      if (result) {
        return result;
      }
    }
  }
};

const selectCategoryForSlug = (state = [], slug, idCatalog) => {
  let category = undefined;
  let categories = state;
  if (idCatalog) {
    categories = state.find((cat) => cat.idCatalog === idCatalog)?.categories;
  }
  for (let i = 0; i < categories?.length; i++) {
    const item = categories[i];
    if (slugify(item.name) === slug) {
      category = item;
      break;
    } else {
      if (item.categories.length > 0) {
        const result = selectCategoryForSlug(item.categories, slug);
        if (result) {
          category = result;
          break;
        }
      }
    }
  }
  return category;
};

export const getCategoryById = (state, categoryId) => {
  return createSelector(
    (state, categoryId) => selectCategoryForId(state.reference.hierarchy.data, parseInt(categoryId)),
    (data) => {
      return data;
    }
  )(state, categoryId);
};

export const getTvShow = (state, tvShowId) => {
  const data = getCategoryById(state, tvShowId);
  if (data) {
    if (
      state &&
      state.content &&
      state.content.tvshow &&
      state.content.tvshow.data &&
      state.content.tvshow.data.rating
    ) {
      data["rating"] = state.content.tvshow.data.rating;
    }
    return new TVShow(data);
  } else {
    return undefined;
  }
};

export const getCategoryBySlug = (state, slug) => {
  return createSelector(
    (state, slug) => {
      const menuItem = selectMenuItem(state, 1);
      const catalogId = menuItem?.id;
      return selectCategoryForSlug(state.reference.hierarchy.data, slug, catalogId);
    },
    (data) => {
      return data;
    }
  )(state, slug);
};

export const getCategoryBySlugAndDate = (state, slug, date) => {
  return createSelector(
    (state, slug) => {
      const menuItem = selectMenuItem(state, 1);
      const catalogId = menuItem?.id;
      return selectCategoryForSlug(state.reference.hierarchy.data, slug, catalogId);
    },
    (data) => {
      const a = data?.categories.find((item) => {
        if (isSameDay(new Date(parseInt(item.name * 1000)), date)) {
          return new Strate(item);
        }
        return undefined;
      });
      return a;
    }
  )(state, slug, date);
};

//CGU
const selectCguContent = (state) => {
  if (!state.reference.faq.data || !state.reference.faq.data.faq) {
    return [];
  }
  return state.reference.faq.data.faq[0];
};

export const getCguContent = createSelector(selectCguContent, (data) => {
  if (data.sections) {
    const cgu = new Faq(data.sections[1]);
    return cgu;
  }
});

export const selectMenuOrdre = (state) => {
  let result = [];
  if (state.reference.menuorder && state.reference.menuorder.data) {
    const memnuorder = state.reference.menuorder.data;
    for (let i = 0; i < memnuorder.length; i++) {
      result.push(Math.abs(memnuorder[i].id));
    }
    return result;
  }
  return [];
};
