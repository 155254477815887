import BackArrow from "components/Icons/Arrowheading";
import PlayerCloseFullscreen from "components/Icons/PlayerCloseFullscreen";
import PlayerOpenFullscreen from "components/Icons/PlayerOpenFullscreen";
import Loader from "components/Loader";
import PlayerSubtitleControler from "components/PlayerSubtitleControler";
import PlayerVolumeControler from "components/PlayerVolumeControler";
import ProgressBar from "components/ProgressBar";
import { saveInLocal } from "helpers/localStorage";
import { isIOS } from "helpers/utility";
import moment from "moment-timezone";
import React, { Component, Fragment } from "react";
import { CSSTransition } from "react-transition-group";

import style from "./style.module.css";

class PlayerOverlayLiveEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubtitleController: false,
      duration: 1,
    };
  }

  handleClickBack(event) {
    event.preventDefault();
    this.props.handleclickonback();
  }

  setShowSubtitleController = (value) => {
    if (this.props.isAdditionalContentVisible) {
      this.props.handleCloseRemote();
    }
    this.setState({ showSubtitleController: value });
  };

  handleClickOverlay() {
    if (
      !this.state.showSubtitleController &&
      !this.props.isAdditionalContentVisible
    ) {
      return this.props.setIsOverlayVisibleToFalse();
    } else if (this.state.showSubtitleController) {
      return this.setState({
        showSubtitleController: false,
      });
    } else if (this.props.isAdditionalContentVisible) {
      return this.props.handleCloseRemote();
    }

    if (this.props.config.canPause === false || this.props.timeshift < 0) {
      return;
    }
  }

  handleClickOnFullscreen(e) {
    e.stopPropagation();
    if (this.props.isAdditionalContentVisible) this.props.onClickRemote();
    if (this.state.showSubtitleController)
      this.setShowSubtitleController(false);
    this.props.onClickFullscreen();
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  async componentDidMount() {
    const { asset } = this.props;
    const startDateTime = moment(
      asset.getStartTime(),
      "YYYY-MM-DD HH:mm:ss"
    ).unix();
    const endDateTime = moment(
      asset.getEndTime(),
      "YYYY-MM-DD HH:mm:ss"
    ).unix();
    saveInLocal("displaycontrols", true);
    const duration = endDateTime - startDateTime;
    this.setState({
      duration,
    });
  }

  render() {
    const {
      isVisible,
      isFullscreen,
      info,
      onChangeVolume,
      viewport,
      onChangeTrack,
      showLoader,
      asset,
    } = this.props;
    const { showSubtitleController, duration } = this.state;
    const currentTime = moment.utc().unix();
    const assetStartTime = moment
      .utc(asset.getStartTime(), "YYYY-MM-DD HH:mm:ss")
      .tz(
        Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || moment.tz.guess()
      )
      .unix();

    return (
      <CSSTransition
        in={isVisible}
        timeout={1000}
        classNames={{
          enter: style.overlayEnter,
          enterActive: style.overlayEnterActive,
          enterDone: style.overlayEnterDone,
          exit: style.overlayExit,
          exitActive: style.overlayExitActive,
          exitDone: style.overlayExitDone,
        }}
      >
        <Fragment>
          <div
            className={style.rootContainer}
            onClick={this.handleClickOverlay.bind(this)}
          >
            <div className={style.headerContainer}>
              <BackArrow
                className={style.headerBack}
                onClick={this.handleClickBack.bind(this)}
              />
              <div className={style.headerTexts}>
                {info.title && (
                  <p className={style.headerTitle}>{info.title}</p>
                )}
                {info.subtitle && (
                  <p className={style.headerSubtitle}>{info.subtitle} </p>
                )}
              </div>
            </div>
            <div className={style.centerIconsContainer}>
              {showLoader && <Loader centered={true} />}
            </div>
            <ProgressBar
              rootClassName={style.progressBar}
              // position={(info.position / info.duration) * 100}
              position={((currentTime - assetStartTime) / duration) * 100}
              draggable={false}
              liveIndicator={true}
            />
            <div className={style.bottomContainer}>
              <PlayerVolumeControler
                setVolume={onChangeVolume.bind(this)}
                currentVolume={info.volume}
                setShowSubtitleController={this.setShowSubtitleController.bind(
                  this
                )}
                viewport={viewport}
              />
              {(info?.tracks?.text?.length > 0 ||
                info?.tracks?.audio?.length > 0) && (
                <PlayerSubtitleControler
                  setShowSubtitleController={this.setShowSubtitleController}
                  showSubtitleController={showSubtitleController}
                  hide={isVisible}
                  tracks={info.tracks}
                  handleTrackChange={onChangeTrack.bind(this)}
                />
              )}
              {!isIOS() && (
                <>
                  {isFullscreen && (
                    <PlayerCloseFullscreen
                      className={style.bottomIcon}
                      onClick={this.handleClickOnFullscreen.bind(this)}
                    />
                  )}
                  {!isFullscreen && (
                    <PlayerOpenFullscreen
                      className={style.bottomIcon}
                      onClick={this.handleClickOnFullscreen.bind(this)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </Fragment>
      </CSSTransition>
    );
  }
}
export default PlayerOverlayLiveEvent;
