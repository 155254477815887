import { setLoginShow } from "actions/ui";
import Loader from "components/Loader";
import Player from "components/Player";
import PlayerErrorMessage from "components/PlayerErrorMessage";
import PlayerReact from "components/PlayerReact";
import consts from "consts/consts";
import ModalValidateParentalCode from "containers/ModalValidateParentalControl";
import { detect } from "detect-browser";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import {
  generateDeviceId,
  isLiveEventAiringNow,
  objectsEqual,
} from "helpers/utility";
import jwt_decode from "jwt-decode";
import get from "lodash.get";
import moment from "moment";
import React, { Fragment, PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
//import vip from "vendors/vip-player";
//import vipConfig from "vendors/vip-config";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const MODAL_VALIDATE_PARENTAL_CODE = "MODAL_VALIDATE_PARENTAL_CODE";
const MODAL_NEW_SUBSCRIPTION = "MODAL_NEW_SUBSCRIPTION";

class PagePlayer extends PureComponent {
  state = {
    show: false,
    withError: false,
    error: null,
    title: null,
    idParent: null,
    startFrom0: false,
    isLoadingLocally: true,
  };

  async checkDevice() {
    // Get Device ID From LocalStorage
    let deviceId = getFromLocal(consts.storageKeys.device);
    let device = { id: deviceId, ...detect() };

    if (this.props.deviceAuthToken) {
      return device;
    }

    // Generate One
    deviceId =
      device.id &&
      device.id !== "" &&
      device.id !== null &&
      device.id !== undefined
        ? device.id
        : generateDeviceId();
    saveInLocal(consts.storageKeys.device, deviceId);

    // Add Device
    await this.props.dispatch(TucanoActions.addDevice(deviceId));

    if (!this.props.deviceAuthToken) {
      throw new Error("Cant Add Device");
    }

    //nGet New device stored
    device = { id: deviceId, ...detect() };

    // Auth Device
    await this.props.dispatch(TucanoActions.authDevice(device));

    return device;
  }

  async fetch() {
    const { isConnected } = this.props;
    // Get asset source query from url to check if the launched asset is a recorded program or a normal asset
    const search = new URLSearchParams(window.location.search);
    const source_type = search.get("source_type") || "";
    const is_asset_source_type_npvr = source_type === "npvr";
    if (isConnected) {
      try {
        await this.checkDevice();
      } catch (e) {
        return;
      }
      await this.props.dispatch(TucanoActions.loginWebPlayer());
    }
    const assetId = this.props.match.params.assetId;
    if (is_asset_source_type_npvr) {
      const { errorReadAsset } = this.props;
      await this.props.dispatch(
        TucanoActions.getNpvrPlayerInfos(assetId, getFromLocal("languageId"))
      );
      if (errorReadAsset) {
        this.setState({ withError: true, error: errorReadAsset.message });
      }
    } else {
      const tvshowId = getFromLocal("tvshowId");
      const data = await this.props.dispatch(
        TucanoActions.getTvShowAsset(
          assetId,
          getFromLocal("languageId"),
          this.props.menuOrder
        )
      );
      try {
        const activeAssets = await this.props.dispatch(
          TucanoActions.getActiveAssets({
            languageId: getFromLocal("languageId"),
            allAssets: 1,
            count: 1000,
          })
        );
        const asset = get(data, "items[0]", undefined);
        let type = null;
        if (activeAssets && activeAssets.activeAssets && data) {
          activeAssets.activeAssets.map((active) => {
            if (active.idAsset === asset.idAsset) {
              type = active.type;
            }
            return null;
          });
        }

        this.setState({ idParent: tvshowId });
        if (asset && type && type !== "MOVIE") {
          let parentId = null;
          if (!tvshowId) {
            // const pathSplit = asset.negpath[1].split("/");
            // parentId = pathSplit.splice(pathSplit.length - 2, 1);
            const hierarchy = this.props.hierarchy[0]?.categories;
            hierarchy &&
              hierarchy.forEach((categorie) => {
                categorie.categories.forEach((subCat) => {
                  if (
                    subCat.name === asset.directMetadata.AD_SURTITLE &&
                    subCat.serie === true
                  ) {
                    parentId = subCat.idCatalog;
                  }
                });
              });
          } else {
            parentId = tvshowId;
          }
          const languageId = getFromLocal("languageId");
          const tvShow = await this.props.dispatch(
            TucanoActions.getTVShow(parentId, languageId)
          );
          if (tvShow && tvShow.items && tvShow.items.length > 0) {
            tvShow.items.forEach((item, index) => {
              const seasonId = get(item, "payload.idCatalog", undefined);
              if (seasonId) {
                this.props.dispatch(
                  TucanoActions.getTVShowEpisodes(
                    seasonId,
                    index,
                    getFromLocal("languageId")
                  )
                );
              }
            });
          }
        }
        const moralityLevel = get(
          asset,
          "directMetadata.AD_CSASNAME",
          undefined
        );
        const adult = get(asset, "adult", false);
        const { activeProfile } = this.props;
        if (
          consts.appModules.profiles === true &&
          activeProfile &&
          ((activeProfile.getMaximumMoralityLevel() >= 1 &&
            activeProfile.getMaximumMoralityLevel() <= 21 &&
            activeProfile.getMaximumMoralityLevel() < moralityLevel) ||
            (activeProfile.getMaximumMoralityLevel() > 0 && adult))
        ) {
          this.showModal(MODAL_VALIDATE_PARENTAL_CODE);
        } else {
          // const { audioPreference, subtitlePreferance, profileToken } =
          //   this.props;
          // let decoded;
          // if (profileToken) {
          //   decoded = jwt_decode(profileToken);
          // }
          // let audio, sub;
          // if (audioPreference) {
          //   audioPreference.map((item) => {
          //     if (
          //       item.profileId === decoded?.id_profile &&
          //       item.audio &&
          //       item.audio.language
          //       // && consts.languageIdMapping[item.audio.language]
          //     ) {
          //       audio = item.audio.language;
          //       // audio = consts.languageIdMapping[item.audio.language];
          //     }
          //   });
          // }

          // if (subtitlePreferance) {
          //   subtitlePreferance.map((item) => {
          //     if (
          //       item.profileId === decoded?.id_profile &&
          //       item.sub &&
          //       item.sub.language
          //       // && consts.languageIdMapping[item.sub.language]
          //     ) {
          //       sub = item.sub.language;
          //       // sub = consts.languageIdMapping[item.sub.language];
          //     }
          //   });
          // }
          await this.props.dispatch(
            TucanoActions.getPlayerInfos(
              data,
              getFromLocal("languageId"),
              // audio ? audio : null,
              // sub ? sub : null,
              null,
              null,
              () => this.showModal(MODAL_NEW_SUBSCRIPTION)
            )
          );
        }
      } catch (error) {
        const { errorBuyAsset, errorTestAsset, errorReadAsset } = this.props;

        if (
          (errorBuyAsset &&
            errorBuyAsset.code &&
            (errorBuyAsset.code === -1612 ||
              errorBuyAsset.code === -2207 ||
              errorBuyAsset.code === -1502 ||
              errorBuyAsset.code === -2109)) ||
          (errorTestAsset && errorTestAsset.code && errorTestAsset.code === -11)
        ) {
          this.showModal(MODAL_NEW_SUBSCRIPTION);
        }
        if (
          errorTestAsset &&
          errorTestAsset.code &&
          errorTestAsset.code !== -11
        ) {
          this.setState({ withError: true, error: errorTestAsset.message });
        }
        if (errorReadAsset) {
          this.setState({ withError: true, error: errorReadAsset.message });
        }
      }
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  getPlayerInfos() {
    this.setState({ show: false });
    const assetId = this.props.match.params.assetId;
    const { audioPreference, subtitlePreferance, profileToken } = this.props;
    let decoded;
    if (profileToken) {
      decoded = jwt_decode(profileToken);
    }
    let audio, sub;
    if (audioPreference) {
      audioPreference.map((item) => {
        if (
          item.profileId === decoded?.id_profile &&
          item.audio &&
          item.audio.language &&
          consts.languageIdMapping[item.audio.language]
        ) {
          audio = consts.languageIdMapping[item.audio.language];
        }
      });
    }

    if (subtitlePreferance) {
      subtitlePreferance.map((item) => {
        if (
          item.profileId === decoded?.id_profile &&
          item.sub &&
          item.sub.language &&
          consts.languageIdMapping[item.sub.language]
        ) {
          sub = consts.languageIdMapping[item.sub.language];
        }
      });
    }
    this.props.dispatch(
      TucanoActions.getPlayerInfos(
        assetId,
        getFromLocal("languageId"),
        audio ? audio.language : null,
        sub ? sub.language : null,
        () => this.showModal(MODAL_NEW_SUBSCRIPTION)
      )
    );
  }

  hideModal(modalType) {
    this.setState({ show: false, modalType: modalType });
    this.props.history.goBack();
  }

  postVideoProgress(position) {
    const assetId = this.props.match.params.assetId;
    this.props.dispatch(
      TucanoActions.postVideoProgress(parseInt(assetId), position)
    );
  }

  showModal(modalType, modalSubType) {
    this.setState({
      show: true,
      modalType: modalType,
      modalSubType: modalSubType,
    });
    if (modalType === MODAL_NEW_SUBSCRIPTION) {
      if (this.props.subscriptionModal) {
        this.props.subscriptionModal(this.props.asset);
      }
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  componentDidMount() {
    const { accountDetail } = this.props;
    const search = new URLSearchParams(window.location.search);
    this.props.dispatch(TucanoActions.resetPlayerInfos());
    const restart = search.get("restart") || "";
    let startFrom0 = false;
    if (restart) {
      startFrom0 = true;
    }

    this.setState({
      isLoadingLocally: false,
      startFrom0,
    });

    if (this.props.isConnected) {
      this.fetch();
    } else {
      // this.props.history.push(consts.routes.signIn.url);
      this.props.dispatch(setLoginShow(true));
    }
    if (accountDetail && accountDetail.status === "INACTIVE") {
      this.setState({
        withError: true,
        error:
          "Your account has been suspended,Please contact our customer service",
        title: "Account suspended",
      });
    }
  }

  async componentDidUpdate(prevProps, _prevState) {
    if (
      this.props.accountStatus &&
      this.props.accountStatus.toLowerCase() === "suspended"
    ) {
      this.props.history.push("/");
      return;
    }
    const assetId = this.props.match.params.assetId;
    if (prevProps.match.params.assetId !== this.props.match.params.assetId) {
      const data = await this.props.dispatch(
        TucanoActions.getTvShowAsset(
          assetId,
          getFromLocal("languageId"),
          this.props.menuOrder
        )
      );
      const moralityLevel = get(
        data,
        "items[0].directMetadata.AD_CSASNAME",
        undefined
      );
      const adult = get(data, "items[0].adult", undefined);
      const { activeProfile } = this.props;
      if (
        consts.appModules.profiles === true &&
        activeProfile &&
        ((activeProfile.getMaximumMoralityLevel() >= 1 &&
          activeProfile.getMaximumMoralityLevel() <= 21 &&
          activeProfile.getMaximumMoralityLevel() < moralityLevel) ||
          (activeProfile.getMaximumMoralityLevel() > 0 && adult))
      ) {
        this.showModal(MODAL_VALIDATE_PARENTAL_CODE);
      } else {
        const { audioPreference, subtitlePreferance, profileToken } =
          this.props;
        let decoded;
        if (profileToken) {
          decoded = jwt_decode(profileToken);
        }
        let audio, sub;
        if (audioPreference)
          audio = audioPreference.filter(
            (item) => item.profileId === decoded?.id_profile
          );
        if (subtitlePreferance)
          sub = subtitlePreferance.filter(
            (item) => item.profileId === decoded?.id_profile
          );
        this.props.dispatch(
          TucanoActions.getPlayerInfos(
            data,
            getFromLocal("languageId"),
            audio ? audio.language : null,
            sub ? sub.language : null,
            () => this.showModal(MODAL_NEW_SUBSCRIPTION)
          )
        );
      }
    }
    if (
      !objectsEqual(prevProps.asset, this.props.asset) &&
      this.props.asset &&
      this.props.asset.isLiveEvent &&
      !isLiveEventAiringNow(
        this.props.asset.getStartTime(),
        this.props.asset.getEndTime()
      )
    ) {
      this.props.history.replace(
        consts.routes.movieAsset.url.replace(":id", assetId)
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetPlayerInfos());
  }

  render() {
    const {
      isConnected,
      liveEPG,
      channels,
      isLoading,
      isTestAssetLoading,
      playerInfos,
      asset,
      seasons,
      previousRoute,
      viewport,
      t,
      isLoadingLicense,
    } = this.props;
    const { startFrom0, isLoadingLocally } = this.state;

    let title, subtitle, isTVShowEpisode, assetId, activeEpisode;

    if (asset) {
      title = asset.getTitle();
      subtitle = undefined;
      assetId = asset.getId();
      isTVShowEpisode = asset.isTVShowEpisode();
      activeEpisode = asset.getEpisodeNumber();
      if (isTVShowEpisode) {
        title = asset.getParentTitle();
        //t("season", { count: tvshow.getSeasonCount() })
        //Season {{seasonNumber}} - Episode {{episodeNumber}}
        subtitle = `${t("Season")} ${asset.getSeasonNumber()} - ${t(
          "Episode"
        )} ${asset.getEpisodeNumber()} : ${asset.getTitle()}`;
      } else if (asset.isLiveEvent) {
        const startDate = moment(asset.getStartTime(), [
          "YYYY-MM-DD HH:mm:ss",
          "HH:mm",
        ]);
        const endDate = moment(asset.getEndTime(), [
          "YYYY-MM-DD HH:mm:ss",
          "HH:mm",
        ]);
        const duration = moment.duration(endDate.diff(startDate)).asMinutes();
        subtitle = `${startDate.format("HH:mm")} - ${endDate.format(
          "HH:mm"
        )} (${duration}${t("MN")})`;
      }
    }

    let providerVideo = "";
    if (playerInfos.url && playerInfos.url.includes("youtube.com")) {
      providerVideo = "youtube";
    }
    if (playerInfos.url && playerInfos.url.includes("vimeo.com")) {
      providerVideo = "vimeo";
    }
    return (
      <div className={style.root}>
        {this.state.show &&
          this.state.modalType === MODAL_VALIDATE_PARENTAL_CODE && (
            <ModalValidateParentalCode
              onCloseClick={this.hideModal.bind(
                this,
                MODAL_VALIDATE_PARENTAL_CODE
              )}
              onCancelClick={this.goBack.bind(this)}
              onConfirmClick={this.getPlayerInfos.bind(this)}
            />
          )}
        {(isLoading || isLoadingLocally || isLoadingLicense) &&
          !this.state.withError && <Loader centered={true} />}

        {!isTestAssetLoading && this.state.withError && (
          <PlayerErrorMessage
            closeAction={() => {
              this.props.history.replace(previousRoute ? previousRoute : "/");
            }}
            message={this.state.error ? this.state.error : null}
            title={this.state.title ? this.state.title : null}
          />
        )}
        {!isLoading && !isLoadingLicense && !isLoadingLocally && (
          <div className={style.root}>
            {playerInfos.url && !this.state.withError && (
              <Fragment>
                {providerVideo !== "" ? (
                  <PlayerReact
                    url={playerInfos.url}
                    previousRoute={previousRoute}
                    handleclickonback={() => {
                      this.props.history.replace(
                        previousRoute ? previousRoute : "/"
                      );
                    }}
                    postVideoProgress={this.postVideoProgress.bind(this)}
                    assetInfo={{
                      title: title,
                      subtitle: subtitle,
                      seek: playerInfos.seek,
                      assetId: assetId,
                    }}
                  />
                ) : (
                  <Player
                    isConnected={isConnected}
                    assetInfo={{
                      idParent: this.state.idParent,
                      title: title,
                      subtitle: subtitle,
                      seek: startFrom0 ? 0 : playerInfos.seek,
                      isTVShowEpisode: isTVShowEpisode,
                      activeEpisode: activeEpisode,
                      seasons: seasons,
                      assetId: assetId,
                      lang: asset?.lang,
                    }}
                    drm={playerInfos.drm}
                    url={playerInfos.url}
                    authToken={this.props.authToken}
                    liveEPG={liveEPG}
                    channels={channels}
                    postVideoProgress={this.postVideoProgress.bind(this)}
                    seasons={seasons}
                    previousRoute={previousRoute}
                    viewport={viewport}
                    subscriptionModal={this.subscriptionModal.bind(this)}
                    asset={asset}
                    t={t}
                  />
                )}
              </Fragment>
            )}
            {!playerInfos.url && (
              <PlayerErrorMessage
                closeAction={() => {
                  this.props.history.replace(
                    previousRoute ? previousRoute : "/"
                  );
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(
  connect((state) => {
    const asset = TucanoSelectors.getAsset(state);
    let seasons = undefined;
    if (asset && asset.isTVShowEpisode() && asset.getParentId()) {
      seasons = TucanoSelectors.getTvShowSeasons(
        state,
        parseInt(asset.getParentId(), 10)
      );
    }
    return {
      menuOrder: TucanoSelectors.selectMenuOrdre(state),
      isLoading: state.player.loading,
      isLoadingLicense: state.player.loadingLicense,
      isTestAssetLoading: state.player.testAsset.loading,
      asset,
      accountDetail: state.account.user.data,
      previousRoute: state.ui.previousRoute,
      subtitlePreferance: state.ui.subtitlePreference,
      audioPreference: state.ui.audioPreference,
      errorBuyAsset: state.player.errorBuyAsset,
      errorTestAsset: state.player.errorTestAsset,
      errorReadAsset: state.player.errorReadAsset,
      authToken: state.session.customerAuthToken,
      liveEPG: TucanoSelectors.getLive(state),
      channels: TucanoSelectors.getEPG(state),
      playerInfos: TucanoSelectors.getPlayerInfos(state),
      profileToken: state.session.profileToken,
      isConnected: state.session.customerAuthToken !== undefined,
      deviceAuthToken: state.session.deviceAuthToken !== undefined,
      viewport: state.ui.viewport,
      seasons,
      activeProfile: TucanoSelectors.getActiveProfile(state),
      hierarchy: state.reference.hierarchy.data,
      accountStatus: state.account.user?.data?.status,
    };
  }),
  withTranslation()
)(PagePlayer);
